<template>
  <div>
    <loader v-if="isLoading" size="xxs" :loader-image="false" />
    <div v-else class="flex flex-col justify-start gap-10">
      <template>
        <div class="flex justify-start items-center gap-0 mt-5">
          <back-button @onClick="$router.back()" variant="secondary" />
          <div class="flex justify-start items-center gap-5 border-l border-jet pl-3">
            <h1 class="text-xl text-left font-extrabold">Get Leave Back Details</h1>
            <breadcrumb :items="breadcrumbs" />
          </div>
        </div>
      </template>
      <template>
        <div class="flex flex-col justify-start gap-5 px-3">
          <card class="p-3 flex flex-col justify-start gap-3">
            <div class="grid grid-cols-3 gap-3">
              <card class="w-full flex justify-start items-start gap-4 p-3">
                <div class="flex flex-col justify-start items-start gap-2">
                  <p class="text-xs font-black uppercase text-romanSilver">Total No. of Get Back Days</p>
                  <p class="font-semibold text-jet text-base capitalize">{{ requestInfo.daysRequested }}</p>
                </div>
              </card>
              <card class="w-full flex justify-start items-start gap-4 p-3">
                <div class="flex flex-col justify-start items-start gap-2">
                  <p class="text-xs font-black uppercase text-romanSilver">Leave Type</p>
                  <p class="font-semibold text-jet text-base capitalize">{{ requestInfo.leaveType }}</p>
                </div>
              </card>
              <card class="w-full flex justify-start items-start gap-4 p-3">
                <div class="flex flex-col justify-start items-start gap-2">
                  <p class="text-xs font-black uppercase text-romanSilver">Approved Leave Duration</p>
                  <p class="font-semibold text-jet text-base capitalize">
                    {{ $DATEFORMAT(new Date(requestInfo.requestStartDate), 'MMM dd, yyyy') }} -
                    {{ $DATEFORMAT(new Date(requestInfo.requestEndDate), 'MMM dd, yyyy') }}
                  </p>
                </div>
              </card>
            </div>
            <card class="w-full flex justify-start items-start gap-4 p-3">
              <div class="flex flex-col justify-start items-start gap-2">
                <p class="text-xs font-black uppercase text-romanSilver">Details</p>
                <p class="font-semibold text-jet text-base capitalize">{{ requestInfo.requestReason }}</p>
              </div>
            </card>
          </card>
          <card>
            <Table
              :headers="headers"
              :items="leaveBackDetails"
              :has-number="false"
            >
              <template v-slot:item="{ item }">
              <span v-if="item.requestDate" class="text-darkPurple text-sm font-normal">
                {{ $DATEFORMAT(new Date(item.data.requestDate), 'MMMM dd, yyyy') }}
              </span>
              <span v-if="item.id" class="text-darkPurple text-sm font-normal">1</span>
              <span v-if="item.justification" class="text-darkPurple text-sm">
                {{ item.data.justification }}
              </span>
              </template>
            </Table>
          </card>
          <card class="p-3 pt-7">
            <div class="border-t border-jet">
              <div class="grid grid-cols-3 gap-4 py-5">
                <div class="flex flex-col justify-start gap-2">
                  <p class="text-base font-semibold">Submitted By:</p>
                  <div class="flex flex-row justify-start items-center gap-2">
                    <div>
                      <div v-if="submittedBy.photo" class="flex justify-center items-center w-24 h-28">
                        <img :src="submittedBy.photo" class="w-24 h-28 rounded" alt="profile photo" />
                      </div>
                      <div v-else class="flex justify-center items-center w-24 h-28 rounded border">
                        <span class="text-blueCrayola text-center font-semibold text-4xl">
                          {{ $getInitials(`${submittedBy.fname} ${submittedBy.lname}`) }}
                        </span>
                      </div>
                    </div>
                    <div class="flex flex-col justify-start gap-2">
                      <p class="text-base text-optimaBlack font-bold">
                        {{ submittedBy.fname }} {{ submittedBy.lname }}
                      </p>
                      <p class="text-lg text-blueCrayola font-bold">
                        <span v-if="submittedBy.userDesignation">
                          {{ submittedBy.userDesignation.name }}
                        </span>
                        <span v-else>-</span>
                      </p>
                    </div>
                    <!-- <div class="flex flex-row justify-start items-center gap-2">
                      <Icon icon-name="icon-message-outline" size="xs" class="" />
                      <p class="text-flame text-sm font-semibold">Send Message</p>
                    </div> -->
                  </div>
                </div>
                <div class="flex flex-col justify-start gap-2">
                  <p class="text-base font-semibold">Get Leave Back For:</p>
                  <div class="flex flex-row justify-start items-center gap-2">
                    <div>
                      <div v-if="userInfo.photo" class="flex justify-center items-center w-24 h-28">
                        <img :src="userInfo.photo" class="w-24 h-28 rounded" alt="profile photo" />
                      </div>
                      <div v-else class="flex justify-center items-center w-24 h-28 rounded border">
                        <span class="text-blueCrayola text-center font-semibold text-4xl">
                          {{ $getInitials(`${userInfo.fname} ${userInfo.lname}`) }}
                        </span>
                      </div>
                    </div>
                    <div class="w-full flex flex-col justify-start gap-2">
                      <p class="text-base text-optimaBlack font-bold">
                        {{ userInfo.fname }} {{ userInfo.lname }}
                      </p>
                      <div class="w-full flex flex-col justify-start gap-2">
                        <div class="w-full flex flex-row justify-start items-center gap-2">
                          <p class="w-2/5 font-bold uppercase text-romanSilver text-10">Designation:</p>
                          <p class="w-3/5 font-semibold uppercase text-xs text-jet">
                            <span v-if="userInfo.userDesignation">{{ userInfo.userDesignation.name }}</span>
                            <span v-else>-</span>
                          </p>
                        </div>
                        <div class="w-full flex flex-row justify-start items-center gap-2">
                          <p class="w-2/5 font-bold uppercase text-romanSilver text-10">Function:</p>
                          <p class="w-3/5 font-semibold uppercase text-xs text-jet">
                            <span v-if="userInfo.orgFunction">{{ userInfo.orgFunction.name }}</span>
                            <span v-else>-</span>
                          </p>
                        </div>
                        <div class="w-full flex flex-row justify-start items-center gap-2">
                          <p class="w-2/5 font-bold uppercase text-romanSilver text-10">Office Location:</p>
                          <p class="w-3/5 font-semibold uppercase text-xs text-jet">
                            <span v-if="userInfo.employeeOffice">{{ userInfo.employeeOffice.name }}</span>
                            <span v-else>-</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex flex-col justify-start gap-2" v-if="$route.query.slug !== 'history'">
                  <p class="text-base font-semibold">Current Approver:</p>
                  <div class="flex flex-row justify-start items-center gap-2">
                    <div>
                      <div v-if="approverInfo.photo" class="flex justify-center items-center w-24 h-28">
                        <img :src="approverInfo.photo" class="w-24 h-28 rounded" alt="profile photo" />
                      </div>
                      <div v-else class="flex justify-center items-center w-24 h-28 rounded border">
                        <span class="text-blueCrayola text-center font-semibold text-4xl">
                          {{ $getInitials(`${approverInfo.fname} ${approverInfo.lname}`) }}
                        </span>
                      </div>
                    </div>
                    <div class="w-full flex flex-col justify-start gap-2">
                      <p class="text-base text-optimaBlack font-bold">
                        {{ approverInfo.fname }} {{ approverInfo.lname }}
                      </p>
                      <div class="w-full flex flex-col justify-start gap-2">
                        <div class="w-full flex flex-row justify-start items-center gap-2">
                          <p class="w-2/5 font-bold uppercase text-romanSilver text-10">Designation:</p>
                          <p class="w-3/5 font-semibold uppercase text-xs text-jet">
                            <span v-if="approverInfo.userDesignation">{{ approverInfo.userDesignation.name }}</span>
                            <span v-else>---</span>
                          </p>
                        </div>
                        <div class="w-full flex flex-row justify-start items-center gap-2">
                          <p class="w-2/5 font-bold uppercase text-romanSilver text-10">Function:</p>
                          <p class="w-3/5 font-semibold uppercase text-xs text-jet">
                            <span v-if="approverInfo.orgFunction">{{ approverInfo.orgFunction.name }}</span>
                            <span v-else>---</span>
                          </p>
                        </div>
                        <div class="w-full flex flex-row justify-start items-center gap-2">
                          <p class="w-2/5 font-bold uppercase text-romanSilver text-10">Office Location:</p>
                          <p class="w-3/5 font-semibold uppercase text-xs text-jet">
                            <span v-if="approverInfo.employeeOffice">{{ approverInfo.employeeOffice.name }}</span>
                            <span v-else>---</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </card>
          <card class="flex flex-row justify-between items-center gap-5 p-5">
            <div class="w-full flex flex-col justify-start">
              <p class="text-lg font-bold">Approvers ({{ approversList.length }})</p>
              <p class="text-sm">You can quickly approve or dissaprove from here.</p>
              <p class="text-sm">You also see other approvers’ responses and comments within the approval trail.</p>
            </div>
            <div class="w-full flex flex-row justify-between items-center">
              <div class="w-full flex flex-col justify-start gap-2">
                <div class="flex flex-row justify-start items-center gap-2">
                  <p class="font-bold text-darkPurple text-xl">
                    <span v-if="submittedBy.requests">
                      {{ Math.ceil(submittedBy.requests.approvalPercentage) }}%
                    </span>
                    <span v-else>-</span>
                  </p>
                  <p class="text-romanSilver text-sm">Completed</p>
                </div>
                <template v-if="submittedBy.requests">
                  <ProgressBar :value="submittedBy.requests.approvalPercentage" />
                </template>
                <p class="font-semibold text-xs text-romanSilver uppercase">
                  <span v-if="submittedBy.requests">
                    {{ submittedBy.requests.approversLeft }} Approvers Left
                  </span>
                  <span v-else>-</span>
                </p>
              </div>
              <div class="w-full flex justify-end">
                <Button
                  label="See Comments"
                  variant="secondary"
                  @onClick="isOpen = true"
                  class="button-class btn-border"
                />
              </div>
            </div>
          </card>
          <template v-if="!$store.getters.hasSubscriptionExpired()">
            <div v-if="approverInfo.userId === $AuthUser.id && $route.query.slug !== 'history'">
              <CTextarea
                placeholder="Insert Comment Here"
                :row="8"
                :height="90"
                :col="130"
                v-model="comment"
              />
            </div>
            <div v-if="approverInfo.userId === $AuthUser.id && $route.query.slug !== 'history'">
              <div class="flex flex-row justify-start items-center gap-5">
                <Button
                  label="Approve"
                  variant="primary btn-bg"
                  @onClick="onApproveRequest"
                  :disabled="isSubmitting"
                  class="button-class"
                />
                <Button
                  label="Disapprove"
                  variant="secondary"
                  @onClick="onDisapproveRequest"
                  :disabled="isSubmitting"
                  class="button-class btn-border"
                />
              </div>
            </div>
          </template>
        </div>
      </template>
    </div>
    <template>
      <RightSideBar
        v-if="isOpen"
        @close="isOpen = false"
        close-button
      >
        <template v-slot:title>
          <p class="text-darkPurple">Approvers ({{ approversList.length }})</p>
        </template>
        <template v-slot:subtitle>
          <p class="font-semibold text-darkPurple pb-4">
            See other approvers comments to this request.
          </p>
        </template>
        <div>
          <div v-for="approver in approversList" :key="approver.userId">
            <ApproversCard
              class="flex justify-start gap-2 bg-white pt-2 px-3 even:bg-ghostWhite border-l-4"
              :class="{
                'border-mediumSeaGreen': approver.currentApprover === 'hasApproved',
                'border-carrotOrange': approver.currentApprover === 'active',
                'border-jet': approver.currentApprover === 'inactive',
              }"
            >
              <div class="flex flex-row justify-between items-center w-full">
                <div class="flex flex-row justify-start items-start gap-2">
                  <div>
                    <div v-if="approver.photo" class="flex justify-center items-center w-10 h-10">
                      <img :src="approver.photo" class="w-10 h-10 rounded" alt="profile photo" />
                    </div>
                    <div v-else class="flex justify-center items-center w-10 h-10 rounded border">
                      <span class="text-blueCrayola text-center font-semibold text-4xl">
                        {{ $getInitials(`${approver.fname} ${approver.lname}`) }}
                      </span>
                    </div>
                  </div>
                  <div class="flex flex-col justify-start items-start gap-1">
                    <div class="flex flex-col justify-start items-start">
                      <p class="block text-base">{{ approver.fname }} {{ approver.lname }}</p>
                      <p class="text-romanSilver uppercase text-xs block">
                        <span v-if="approver.userDesignation">
                          {{ approver.userDesignation.name }}
                        </span>
                        <span v-else>-</span>
                      </p>
                      <p class="block text-xs">
                        <span v-if="approver.actionTakenAt">
                          {{ $DATEFORMAT(new Date(approver.actionTakenAt), "MMMM dd, yyyy") }}
                        </span>
                        <span v-else>-</span>
                      </p>
                    </div>
                    <template>
                      <div v-if="approver.comment" class="text-sm text-jet break-words">
                        <p class="font-normal text-romanSilver leading-tight">Comment:</p>
                        <p class="leading-tight"> {{ approver.comment }}</p>
                      </div>
                      <p class="leading-tight" v-else>--No Comment--</p>
                    </template>
                  </div>
                </div>
                <Tag
                  :class="{
                    'approved': approver.currentApprover === 'hasApproved',
                    'pending': approver.currentApprover === 'active',
                    'not-started': approver.currentApprover === 'inactive',
                  }"
                >
                  <span v-if="approver.currentApprover === 'hasApproved'">Approved</span>
                  <span v-if="approver.currentApprover === 'active'">Pending</span>
                  <span v-if="approver.currentApprover === 'inactive'">Pending</span>
                </Tag>
              </div>
            </ApproversCard>
          </div>
        </div>
      </RightSideBar>
    </template>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex"
import CTextarea from "@scelloo/cloudenly-ui/src/components/text-area"
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button"
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb"
import Button from "@scelloo/cloudenly-ui/src/components/button"
import Card from "@scelloo/cloudenly-ui/src/components/card"
import Table from "@scelloo/cloudenly-ui/src/components/table"
import ProgressBar from "@/components/ProgressBar"
import ApproversCard from "@/components/ApproversCard"
import RightSideBar from "@/components/RightSideBar"
import Tag from "@/components/Tag"

export default {
  components: {
    BackButton,
    Breadcrumb,
    Card,
    Table,
    Button,
    ProgressBar,
    CTextarea,
    Tag,
    ApproversCard,
    RightSideBar,
  },
  data() {
    return {
      isOpen: false,
      isSubmitting: false,
      requestInfo: {},
      userInfo: {},
      categoryId: null,
      employeeReviews: [],
      leaveBackDetails: [],
      comment: null,
      breadcrumbs: [
        { disabled: false, text: "Approval", href: "approval", id: "Approval" },
        { disabled: false, text: "My Approvals", href: "my approvals", id: "My Approvals" },
        { disabled: false, text: "Get Leave Back Details", id: "Get Leave Back Details" },
      ],
      headers: [
        { title: "Leave Back Day (s)", value: "requestDate" },
        { title: "Total No. of Days", value: "id" },
        { title: "Justification", value: "justification" },
      ],
    };
  },
  computed: {
    ...mapState('approval', ['approversList', 'isLoading']),
    ...mapGetters('approval', ['approverInfo', 'submittedBy'])
  },
  methods: {
    ...mapActions({
      setIsLoading: 'approval/setIsLoading',
      getApprovalProgress: 'approval/getApprovalProgress',
      getMyApprovalRequests: 'approval/getMyApprovalRequests',
    }),
    onApproveRequest() {
      this.isSubmitting = true
      this.onSubmitApprovalRequest({
        hasApproved: true,
        userId: this.$AuthUser.id,
        requestId: this.$route.params.id,
        comment: this.comment,
        requestSlug: this.approverInfo.requestSlug,
        whoToNotify: this.submittedBy.userId,
        initiatorId: this.submittedBy.userId,
        categoryId: this.categoryId,
        orgId: this.$orgId
      })
    },

    onDisapproveRequest() {
      this.isSubmitting = true
      this.onSubmitApprovalRequest({
        hasApproved: false,
        userId: this.$AuthUser.id,
        requestId: this.$route.params.id,
        comment: this.comment,
        requestSlug: this.approverInfo.requestSlug,
        whoToNotify: this.submittedBy.userId,
        initiatorId: this.submittedBy.userId,
        categoryId: this.categoryId,
        orgId: this.$orgId
      })
    },

    onSubmitApprovalRequest(payload){
      this.$_respondApprovalRequest(payload).then(({ data }) => {
        this.$toasted.success(data.message, { duration: 3000 })
        this.$router.back({ name: "MyApprovals" })
        this.isSubmitting = false
      }).catch((error) => {
        this.$toasted.error(error.message, { duration: 3000 })
        this.isSubmitting = false
      })
    },
    async getDaysBackRequest(leaveBackId) {
      await this.$_getDaysBackRequest({ leaveBackId }).then(({ data }) => {
        this.leaveBackDetails = data.request.leaveBackRequestDetails
        this.categoryId = data.request.leaveRequest.category.id
        this.userInfo = data.user
        this.requestInfo = {
          ...data.request,
          leaveType: data.request?.leaveRequest?.category?.name,
          requestReason: data.request?.requestReason,
          requestEndDate: data.request?.requestEndDate,
          requestStartDate: data.request?.requestStartDate,
        }
      })
    }
  },
  async created() {
    this.setIsLoading(true)
    await this.getApprovalProgress(this.$route.params.id)
    await this.getMyApprovalRequests({ userId: this.$AuthUser.id }, {params : '', paginate: "?page=1&perPage=1000", module: '&platform=admin'})
    await this.getDaysBackRequest(this.$route.params.id)
    this.setIsLoading(false)
  },
};
</script>

<style>
.rightSidebar {
  width: 520px !important;
}
.text-10{
  font-size: 10px !important;
}
.button-class {
  padding: 10px 40px !important;
  font-weight: 600;
  display: flex;
  justify-self: end;
}
.pending {
  background: rgba(233, 147, 35, 0.08);
  color: #e99323;
  text-align: center;
  padding: 8px;
  border-radius: 4px;
}
.approved {
  background: rgba(19, 181, 106, 0.08);
  color: #13b56a;
  text-align: center;
  padding: 8px;
  border-radius: 4px;
}
.disapproved {
  background: rgba(234, 60, 83, 0.08);
  color: #ea3c53;
  text-align: center;
  padding: 8px;
  border-radius: 4px;
}
.not-started {
  background: #F6F8F9;
  color: #9BA0A7;
  text-align: center;
  padding: 8px;
  border-radius: 4px;
}

.btn-bg {
    background-color: var(--dynamic-Background) !important;
 }

.btn-border{
  color: var(--dynamic-Background) !important;
  border: 1px solid var(--dynamic-Background) !important;
}
</style>
